import React from "react"
import styled from "styled-components"

import BlurLoader from "../reusable-components/loaders/blur-loader"

const QuoteStatus = () => {
  return (
    <BlurLoader loading={false} message="Fetching">
      <StyledContainer>
        <h2>Quote Details</h2>
        <h3>
          Thank you for your quote request! One of our staff members will
          contact you shortly to finalise your request.
        </h3>
      </StyledContainer>
    </BlurLoader>
  )
}

export default QuoteStatus

// ===============
//     STYLES
// ===============
const StyledContainer = styled.div`
  h2 {
    margin-bottom: 2rem;
  }
  h3 {
    margin-bottom: 1.5rem;
  }
`
