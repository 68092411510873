import React, { useContext } from "react"
import styled from "styled-components"

import BlurLoader from "../reusable-components/loaders/blur-loader"

import { getProperty } from "../../lib/utils"

const OrderStatus = ({ orderData = {} }) => {
  // Fallback message
  let orderMessage = "Your order has been placed"

  const meta_data = orderData?.meta_data || []

  const collectionMessage = meta_data?.find?.(
    (met) => met?.key == "collection_message"
  )?.value

  // Generic order messages for most payment methods
  if (orderData.status == "on-hold") {
    orderMessage =
      "Your order has been placed but is awaiting payment. Please click the button labeled Try Again to attempt payment now"
  } else if (
    orderData.status == "processing" ||
    orderData.status == "completed"
  ) {
    orderMessage =
      "Thank you for order! Your payment has been received and your order is being processed"
  } else if (orderData.status == "failed") {
    orderMessage =
      "Something went wrong while processing your payment. Please click the button labeled Try Again to attempt payment now"
  }

  // Specific order message that depend on payment method
  // This will override the generic ones above
  if (orderData.payment_method == "bacs") {
    if (orderData.status == "on-hold") {
      orderMessage =
        "Thank you for order! Your order is on hold awaiting bank transfer"
    }
  }

  // Get EFT account details
  const accountDetails = getProperty(orderData, [
    "payment_method_account_details",
    0,
  ])

  // Get Special Delivery Instruction meta data
  const deliveryInstruction = orderData?.meta_data?.find?.(
    (met) => met?.key == "delivery_instruction"
  )?.value

  // We have something basic here just to be readable.
  return (
    <BlurLoader loading={!orderData.id} message="Fetching">
      <StyledContainer>
        <h2>Order Details (Order ID: {orderData.order_number})</h2>
        <h3>{orderMessage}</h3>
        {orderData.status == "processing" &&
          collectionMessage &&
          collectionMessage != "" && (
            <CollectionMessageStyling>
              {collectionMessage}
            </CollectionMessageStyling>
          )}
        <OrderInfoItem>
          {"Payment Method: "}
          <span>
            {orderData.payment_method_title} ({orderData.payment_method})
          </span>
        </OrderInfoItem>
        <OrderInfoItem>
          {"Payment Status: "}
          <span>{orderData.status}</span>
        </OrderInfoItem>
        <OrderInfoItem>
          {"Contact Details: "}
          <span>
            {orderData.billing && orderData.billing.first_name}{" "}
            {orderData.billing && orderData.billing.last_name}
          </span>
        </OrderInfoItem>
        <OrderInfoItem>
          {"Delivery Address: "}
          <div>{orderData.billing && orderData.billing.address_1}</div>
          <div>{orderData.billing && orderData.billing.address_2}</div>
          <div>{orderData.billing && orderData.billing.city}</div>
          <div>{orderData.billing && orderData.billing.postcode}</div>
          <div>{orderData.billing && orderData.billing.state}</div>
          {deliveryInstruction ? "Delivery Instruction: " : null}
          {deliveryInstruction ? (
            <div className="deliveryNote">{deliveryInstruction}</div>
          ) : null}
        </OrderInfoItem>
        {orderData.payment_method != "bacs" ? null : (
          <OrderInfoDescription>
            {orderData.payment_method_description}
            <OrderInfoDescription>
              <OrderInfoItem>
                {"Account Name: "}
                <span>{accountDetails.account_name}</span>
              </OrderInfoItem>
              <OrderInfoItem>
                {"Bank Name: "}
                <span>{accountDetails.bank_name}</span>
              </OrderInfoItem>
              <OrderInfoItem>
                {"Account Number: "}
                <span>{accountDetails.account_number}</span>
              </OrderInfoItem>
              <OrderInfoItem>
                {"Branch Number: "}
                <span>{accountDetails.sort_code}</span>
              </OrderInfoItem>
              <OrderInfoItem>
                {"Reference: "}
                <span>{orderData.order_number}</span>
              </OrderInfoItem>
            </OrderInfoDescription>
          </OrderInfoDescription>
        )}
      </StyledContainer>
    </BlurLoader>
  )
}

export default OrderStatus

// ===============
//     STYLES
// ===============
const StyledContainer = styled.div`
  h2 {
    margin-bottom: 2rem;
  }
  h3 {
    margin-bottom: 1.5rem;
  }
`
const OrderInfoItem = styled.div`
  margin: 10px 0;
  font-weight: bold;
  span {
    font-weight: normal;
  }
  div {
    font-weight: normal;
    line-height: 1.5;
  }
  .deliveryNote {
    font-style: italic;
  }
`
const OrderInfoDescription = styled.div`
  margin: 20px 0;
`
const CollectionMessageStyling = styled.p`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.yellow};
`
