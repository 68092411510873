import React, { useState, useContext } from "react"

import CheckoutWrapper, {
  CheckoutSplitStyling,
  RightSideSection,
} from "../../components/checkout/checkout-wrapper"
import OrderSummary from "../../components/checkout/order-summary"
import OrderStatus from "../../components/checkout/order-status"
import ContinueButton from "../../components/checkout/continue-button"
import CheckoutContext from "../../context/checkout/checkout-context"

import useOrderFetcher from "../../hooks/checkout-order-fetcher"
import { navigate } from "gatsby"
import QuoteStatus from "../../components/checkout/quote-status"

const Summary = ({ location }) => {
  const [apiError, setApiError] = useState("")
  const [orderData] = useOrderFetcher({
    setApiError,
  })

  const { selectedPaymentMethod } = useContext(CheckoutContext)

  return (
    <CheckoutWrapper checkoutStep="summary" location={location}>
      {apiError && <p>{apiError}</p>}
      <CheckoutSplitStyling>
        {selectedPaymentMethod?.paymentCode === "quoterequest" ? (
          <QuoteStatus></QuoteStatus>
        ) : (
          <OrderStatus orderData={orderData} />
        )}
        <RightSideSection>
          <OrderSummary
            onlyShowOrder
            givenOrder={orderData}
            showShipping={true}
          />
          {orderData.status === "completed" ||
          orderData.status === "processing" ||
          selectedPaymentMethod == "bacs" ? null : (
            <ContinueButton
              callback={() =>
                navigate(`/checkout/payment/?orderid=${orderData.id}`)
              }
              text="Redo Payment"
            />
          )}
        </RightSideSection>
      </CheckoutSplitStyling>
    </CheckoutWrapper>
  )
}

export default Summary
